<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="SERVICES"
          :breadcrumb="[
            {
              label: 'Services',
            },
            { label: 'Services-List' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
           
            >
            <div class="card-body">
                <div class="row">
                  <div class="col text font">

                    <h4>
                      <b style="color: #00364f; font-weight: bolder">Services </b>
                    </h4>
                  </div>
                  <div class="col text-end ">
                    
                    &nbsp;<button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>

                  </div>
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>








                <!-- start list area  -->
                <div class="row"  style="margin-top:5px">
               
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
      
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm text">
                        <tr class="tr-head table-wrapper-scroll-y rounded-circle">
                          <th class="text-truncate">&nbsp;&nbsp;+&nbsp;&nbsp;</th>
                          <th class="text-truncate">Code</th>
                          <th class="text-truncate">Service</th>
                          <th class="text-truncate">VakilGiri Fees</th>
                          <th class="text-truncate">Professional Fees</th>
                          <th class="text-truncate">Admin Fees</th>
                          <th class="text-truncate">Steps</th>
                          <th class="text-truncate">Docs</th>
                          <th class="text-truncate">FAQ</th>
                          <th class="text-truncate">Govt Fees</th>
                          <th class="text-truncate">Modules</th>
                          <th class="text-truncate">Status</th>
                          <th class="text-truncate">Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(service, index) in services"
                            :key="index"
                          >
                            <td>
                              <span v-if="service.logo">
                                <img
                                  style="width: 30px; height: 30px"
                                  :src="
                                    this.$store.state.imgUrl +
                                    `/service-icons/${service.id}/thumbs/${service.logo}`
                                  "
                                  alt=""
                                />
                              </span>
                              <span v-else>
                                <img
                                  style="width: 30px; height: 30px"
                                  :src="this.$store.state.placeholderImg"
                                  alt=""
                                />
                              </span>
                            </td>
                            <td>
                              <span class="text-truncate"> {{ service.code }}</span>
                            </td>
                            <td>
                              <span class="text-truncate"> {{ service.name }}</span>
                            </td>
                            <td>
                              <span class="text-truncate">
                                {{ service.superAdminFee }}</span
                              >
                            </td>
                            <td>
                              <span class="text-truncate">
                                {{ service.professionalFee }}</span
                              >
                            </td>
                            <td>
                              <span class="text-truncate"> {{ service.adminFee }}</span>
                            </td>
                            <td>
                              <span class="text-truncate">
                                <button
                                  type="button"
                                  @click="stepslinking(service)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#stepslinking"
                                  class="btn text-white btn-sm steps-link"
                                  style="
                                    background-color: #0ead38;
                                    padding: 5px 7px 5px 7px;
                                  "
                                >
                                  <b>+</b>
                                </button>
                              </span>
                            </td>

                            <td>
                              <span class="text-truncate">
                                <button
                                  type="button"
                                  @click="servDocumentLoad(service)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#SrvDocuments"
                                  class="btn text-white btn-sm government-fees"
                                  style="background-color: blue; padding: 5px 7px 5px 7px"
                                  title="Service Documents"
                                >
                                  <b>+</b>
                                </button>
                              </span>
                            </td>
                            <td>
                              <span class="text-truncate">
                                <button
                                  type="button"
                                  @click="getServiceInfo(service)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#faqs"
                                  class="btn text-white btn-sm government-fees"
                                  style="
                                    background-color: #fc9003;
                                    padding: 5px 7px 5px 7px;
                                  "
                                  title="FAQ's"
                                >
                                  <b>+</b>
                                </button>
                              </span>
                            </td>
                            <td class="text-center">
                              <span class="text-truncate" @click="classActiveTab()">
                                <button
                                  type="button"
                                  @click="gvtFees(service)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#GovtFees"
                                  class="btn text-white btn-sm government-fees"
                                  style="
                                    background-color: #00364f;
                                    padding: 5px 7px 5px 7px;
                                  "
                                  title="Government Fees"
                                >
                                  <b>+</b>
                                </button>
                              </span>
                            </td>

                            <td>
                              <span class="text-truncate">
                                <button
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#addones"
                                  class="btn text-white btn-sm Modules"
                                  style="
                                    background-color: rgb(242 19 1);
                                    padding: 5px 7px 5px 7px;
                                  "
                                  @click="addOns(service)"
                                  title="Modules"
                                >
                                  <b>+</b>
                                </button>
                              </span>
                            </td>

                            <td>
                              <span class="text-truncate">
                                <p
                                  v-if="service.status == 'true'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> Active</b>
                                </p>
                                <p
                                  v-else
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: red;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> InActive</b>
                                </p>
                              </span>
                            </td>

                            <td>
                            

                              <span class="text-truncate">
                                <div class="btn-group btn-group-sm" role="group">
                                  <router-link
                                    class="d-flex align-items-center btn btns btn-sm text-white"
                                    :to="`/super-admin/service-details-view/${service.id}`"
                                    title="View Service"
                                    type="button"
                                    style="padding:5px 4.5px 5px 4.5px;background-color:#00364f;5px 4.5px 5px 4.5px;"
                                  >
                                    <font-awesome-icon icon="eye" />
                                  </router-link>
                                  <button
                                    type="button"
                                    data-bs-toggle="modal"
                                    @click="editServices(service)"
                                    data-bs-target="#editexampleModal"
                                    class="btn btns btn-sm btn-success Edit"
                                    style="padding: 5px 4.5px 5px 4.5px"
                                    title="Edit "
                                  >
                                    <font-awesome-icon icon="edit" />
                                  </button>
                                </div>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <div class="card-footer" >
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="getServices(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="getServices(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="getServices(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="getServices(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="getServices(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="getServices(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="getServices(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="getServices(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="getServices(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="getServices( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="getServices( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="getServices(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="getServices(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="getServices(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="getServices(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="getServices(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- service Doccuments Modal -->
  <div
    class="modal fade"
    id="SrvDocuments"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong style="color: #00364f">Service Documents</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
  
         <form @submit.prevent="DoccVal == 'false' ? servDocumentSave() : servDocumentUpdate()">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
              <label><strong>Documents <small style = "color:red">*</small></strong></label>
              <input
              required
                type="text"
                v-model="form_Docc.document"
                class="form-control"
                placeholder="Document Name"
                style="height: 30px"
              />
            </div>
            <!-- <div class="col-xl-4 col-md-4 col-12" style="padding: 5px 5px 5px 5px">
              <label><strong>Format</strong></label>
              <input
                type="text"
                v-model="form_Docc.format"
                class="form-control"
                placeholder="format"
                style="height: 30px"
              />
            </div>
            <div class="col-xl-4 col-md-4 col-12" style="padding: 5px 5px 5px 5px">
              <label><strong>Size</strong></label>
              <input
                type="text"
                v-model="form_Docc.size"
                class="form-control"
                placeholder="size"
                style="height: 30px"
              />
            </div>
            <div class="col-xl-4 col-md-4 col-12" style="padding: 5px 5px 5px 5px">
              <label><strong>Thumbnail</strong></label>

              <select
                v-model="form_Docc.thumbnail"
                class="form-select"
                aria-label="Default select example"
                style="height: 30px; padding-top: 2px"
              >
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
            </div>
            <div
              class="col-xl-4 col-md-4 col-12"
              style="padding: 5px 5px 5px 5px"
              v-if="form_Docc.thumbnail == 'true'"
            >
              <label><strong>Width</strong></label>
              <input
                type="text"
                v-model="form_Docc.width"
                class="form-control"
                placeholder="width"
                style="height: 30px"
              />
            </div>
            <div
              class="col-xl-4 col-md-4 col-12"
              style="padding: 5px 5px 5px 5px"
              v-if="form_Docc.thumbnail == 'true'"
            >
              <label><strong>Height</strong> </label>
              <input
                type="text"
                v-model="form_Docc.height"
                class="form-control"
                placeholder="height"
                style="height: 30px"
              />
            </div> -->

            <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
              <label>Remark</label>
              <!-- <input type="text" v-model="form_Docc.remark"  class="form-control" placeholder="height" style="height:30px;"> -->
              <textarea
                rows="3"
                v-model="form_Docc.remark"
                class="form-control"
                placeholder="Remark"
              ></textarea>
            </div>
            <div
              class="col-xl-12 col-md-12 col-12 text-end"
              style="padding: 5px 5px 5px 5px"
            >
              <button
                type="submit"
            
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                <b>Confirm</b>
              </button>
            </div>
          </div>

         </form>

          <hr />
          <div class="row">
            <div class="col-12">

              <div
                  class="container-fluid table-scroll"
                >

                <table class="table table-hover table-sm">
                    <tr class="text tr-head table-wrapper-scroll-y rounded-circle"
                  style="background-color: #00364f; color: white"
                >
                  <th style="width: 35%">Documents</th>
                  <th style="width: 15%">Format</th>
                  <th style="width: 10%">Size</th>
                  <!-- <th style="width: 10%">Thumbnail</th>
                  <th style="width: 10%">Width</th>
                  <th style="width: 10%">Height</th> -->
                  <th style="width: 10%" class="text-end">Action</th>
                </tr>

                <tbody>
                  <!-- v-for="(srvStep, index) in serviceSteps" :key="index" -->
                  <tr
                    style="color: #00364f"
                    v-for="(docc, index) in allDoccs"
                    :key="index"
                  >
                    <td>{{ docc.document }}</td>
                    <td>{{ docc.format }}</td>
                    <td>
                      <span v-if="docc.size == '2048'"> 2MB </span>
                      <span v-else> {{ docc.size }}KB </span>
                    </td>
                    <!-- <td>{{ docc.thumbnail }}</td>
                    <td>{{ docc.width }}</td>
                    <td>{{ docc.height }}</td> -->
                    <td class="text-end">
                      <div class="btn-group btn-group-sm" role="group">
                        <button
                          type="button"
                          @click="servDocumentEdit(docc)"
                          class="btn btns btn-sm btn-success"
                          style="padding: 5px 4.5px 5px 4.5px"
                        >
                          <font-awesome-icon icon="edit" />
                        </button>
                        <button
                          type="button"
                          @click="servDocumentDelete(docc.id)"
                          class="btn btns btn-sm btn-danger"
                          style="padding: 5px 4.5px 5px 4.5px"
                        >
                          <font-awesome-icon icon="trash" />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
                </div>
           
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn text-white btn-sm"
            style="background-color: #f21300"
            data-bs-dismiss="modal"
          >
            <b>Cancel</b>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal for steps linking  -->
  <div
    class="modal fade"
    id="stepslinking"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-title" style="color: #00364f">
            <b>Sevice Steps</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#homeStep"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Link
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profileStep"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Create
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <!-- for link -->
            <div
              class="tab-pane fade show active"
              id="homeStep"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div class="row">
                <div class="col-xl-12" >
                  <form
                    @submit.prevent="
                      edit == 'false' ? saveServiceSteps() : updateServiceSteps()
                    "
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr
                          class="text table-wrapper-scroll-y rounded-circle"
                          style="background-color: #00364f; color: white"
                        >
                          <th style="width: 40%">Step</th>
                          <th style="width: 20%">Priority</th>
                          <th style="width: 5%">Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(srvStep, index) in serviceSteps"
                            :key="index"
                          >
                            <td>
                              <span v-if="srvStep.step">{{ srvStep.step.title }}</span>
                            </td>
                            <td>{{ srvStep.priority }}</td>

                            <td class="text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                title="Edit"
                                  type="button"
                                  @click="editServiceSteps(srvStep)"
                                  class="btn btns btn-sm btn-success"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>
                                <button
                                title="Delete"
                                  type="button"
                                  @click="deleteServiceSteps(srvStep.id)"
                                  class="btn btns btn-sm btn-danger"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="trash" />
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="form-group">
                                <select
                                  class="form-select"
                                  v-model="form_step.step_id"
                                  aria-label="Default select example"
                                  style="height: 33px;padding:5px 5px 5px 5px"
                                  required
                                >
                                  <option selected disabled>Select Step...</option>

                                  <option
                                    v-for="(step, index) in steps"
                                    :key="index"
                                    :value="step.id"
                                  >
                                    {{ step.title }}
                                  </option>
                                </select>
                                <small style="color: red" v-if="form_step.errors"
                                  ><span v-if="form_step.errors.error">
                                    {{ form_step.errors.error }}</span
                                  >
                                </small>
                              </div>
                            </td>
                            <td>
                              <div class="form-group">
                                <input
                                  type="number"
                                  v-model="form_step.priority"
                                  class="form-control"
                                  placeholder="Priority"
                                  style="height: 33px"
                                  required
                                />
                              </div>
                            </td>

                            <td class="text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                @click="clearStepForm()"
                                title="Clear Form"
                                  type="button"
                                  class="btn  text-white btn-sm"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #f21000;
                                  "
                                >
                                 C
                                </button>
                                <button
                                            title="Save"
                                  type="submit"
                                  class="btn  text-white btn-sm"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="floppy-disk" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <!-- for next create -->

            <div
              class="tab-pane fade"
              id="profileStep"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <form
                id="FormID"
                @submit.prevent="
                  formStepCreateedit == 'false'
                    ? stepsNewCreateForStep()
                    : stepsUpdateForStep()
                "
              >
         
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Name <span style="color: #f21000">*</span></label>
                    <input
                      
                      type="text"
                      class="form-control"
                      placeholder="Title"
                      v-model="formStepCreate.title"
                      required
                    />
                    <!-- @change="lenthheker($event, 'stepname')"
                    <span style="color: #f21000" id="stepname"></span> -->



                    <small style="color: red" v-if="formStepCreate.errors"
                        ><span v-if="formStepCreate.errors.error"
                          ><span v-if="formStepCreate.errors.error.title">{{
                            formStepCreate.errors.error.title[0]
                          }}</span></span
                        ></small
                      >
                  </div>
</div>

<div class="col-12">
  <div class="form-group mt-1">
                    <label>Remark</label>
                    <textarea
                      
                      class="form-control"
                      rows="3"
                      placeholder="remark"
                      v-model="formStepCreate.remark"
                    ></textarea>
                  </div>
</div>
                </div>
             

                 
                
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn text-white btn-sm"
                    style="background-color: red"
                    data-bs-dismiss="modal"
                  >
                    <b>Close</b>
                  </button>
                  <button
                    type="submit"
                    class="btn text-white btn-sm"
                    style="background-color: #00364f"
                  >
                    <b>Confirm</b>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--start model  Services Edit-->
  <div
    class="modal font fade"
    id="editexampleModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content" >
        <div class="modal-header bg-white" >
          <h4 class="modal-title" id="exampleModalLabel" style="color: #00364f">
            <b>Services Edit</b>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="color: #00364f">
          <form @submit.prevent="updateServices">
            <div class="row">
              <div class="col-12" v-if="msgImgError">
                <div class="alert alert-danger p-1" role="alert">
                  <small>{{ msgImgError }}</small>
                </div>
              </div>

              <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
              
                <div>
                  <label for="serviceLogo">
                    <img style="width: 50px; height: 50px" :src="serviceIconImg" alt="" />
                  </label>
               
                </div>

                <input
                  @change="serviceIconImgUpload"
                  class="input-file"
                  style="position: absolute; top: -150000px"
                  id="serviceLogo"
                  type="file"
                  accept="image/*"
                />
              </div>
              <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
              ></div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-6"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Code</label>
                  <input
                    type="tel"
                    class="form-control"
                    v-model="Form_service_update.code"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    disabled
                    readonly
                  />
                </div>
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-6"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="Form_service_update.name"
                    aria-label="Disabled input example"
                    disabled
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    readonly
                  />
                </div>
              </div>

              <div
                class="col-xl-6 col-md-6 col-sm-6 col-6"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Minimum Member <small style="color:red">*</small></label>
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    placeholder="Minimum Member"
                    @change="minMemberVal($event, 'minmamValidation')"
                    v-model="Form_service_update.minMember"
                  />
                  <!-- <span id="minmamValidation"></span> -->
                  <span style="color: #f21000" id="minmamValidation"></span>

                  <small style="color: red" v-if="Form_service_update.errors"
                        ><span v-if="Form_service_update.errors.error"
                          ><span v-if="Form_service_update.errors.error.minMember">{{
                            Form_service_update.errors.error.minMember[0]
                          }}</span></span
                        ></small
                      >
                </div>
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-6"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Maximum Member <small style="color:red">*</small></label>
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    placeholder="Maximum Member"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="Form_service_update.maxMember"
                    @change="minMemberVal($event, 'maxmamValidation')"
                  />
                  <span style="color: #f21000" id="maxmamValidation"></span>

                  <small style="color: red" v-if="Form_service_update.errors"
                        ><span v-if="Form_service_update.errors.error"
                          ><span v-if="Form_service_update.errors.error.maxMember">{{
                            Form_service_update.errors.error.maxMember[0]
                          }}</span></span
                        ></small
                      >
                </div>
              </div>

              <div
                class="col-xl-6 col-md-6 col-sm-6 col-6"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Super Admin Fees <small style="color:red">*</small></label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Super Admin Fees"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="Form_service_update.superAdminFee"
                  />

                  <small style="color: red" v-if="Form_service_update.errors"
                        ><span v-if="Form_service_update.errors.error"
                          ><span v-if="Form_service_update.errors.error.superAdminFee">{{
                            Form_service_update.errors.error.superAdminFee[0]
                          }}</span></span
                        ></small
                      >
                </div>
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-6"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Professional Fees <small style="color:red">*</small></label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Professional Fees"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="Form_service_update.professionalFee"
                  />
                  <small style="color: red" v-if="Form_service_update.errors"
                        ><span v-if="Form_service_update.errors.error"
                          ><span v-if="Form_service_update.errors.error.professionalFee">{{
                            Form_service_update.errors.error.professionalFee[0]
                          }}</span></span
                        ></small
                      >
                </div>
              </div>

              <div
                class="col-xl-6 col-md-6 col-sm-6 col-6"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Admin Fees <small style="color:red">*</small></label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Admin Fees"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="Form_service_update.adminFee"
                  />
                  <small style="color: red" v-if="Form_service_update.errors"
                        ><span v-if="Form_service_update.errors.error"
                          ><span v-if="Form_service_update.errors.error.adminFee">{{
                            Form_service_update.errors.error.adminFee[0]
                          }}</span></span
                        ></small
                      >
                </div>
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-6"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Government Fees <small style="color:red">*</small></label>
                  <input
                    type="number"
                    class="form-control"
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    placeholder="Government Fees"
                    v-model="Form_service_update.governmentFee"
                  />


                  <small style="color: red" v-if="Form_service_update.errors"
                        ><span v-if="Form_service_update.errors.error"
                          ><span v-if="Form_service_update.errors.error.governmentFee">{{
                            Form_service_update.errors.error.governmentFee[0]
                          }}</span></span
                        ></small
                      >
                </div>
              </div>

              <div
                class="col-xl-6 col-md-6 col-sm-6 col-6"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Super Admin Gst Required</label>
                  <select
                    class="form-select"
                    v-model="Form_service_update.superAdminFeeGstRequired"
                    aria-placeholder="Status"
                    placeholder="Status"
                    style="
                      height: 30px;
                      padding: 5px 5px 5px 5px;
                      border: 1px solid #00364f;
                    "
                  >
                    <option value="true">Yes</option>
                    <option selected value="false">No</option>
                  </select>
                </div>
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-6"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Admin Fees Gst Required</label>
                  <select
                    class="form-select"
                    v-model="Form_service_update.adminFeeGstRequired"
                    aria-placeholder="Status"
                    placeholder="Status"
                    style="
                      height: 30px;
                      padding: 5px 5px 5px 5px;
                      border: 1px solid #00364f;
                    "
                  >
                    <option value="true">Yes</option>
                    <option selected value="false">No</option>
                  </select>
                </div>
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-6"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>professionalGstRequired</label>
                  <select
                    class="form-select"
                    v-model="Form_service_update.professionalGstRequired"
                    aria-placeholder="Status"
                    placeholder="Status"
                    style="
                      height: 30px;
                      padding: 5px 5px 5px 5px;
                      border: 1px solid #00364f;
                    "
                  >
                    <option value="true">Yes</option>
                    <option selected value="false">No</option>
                  </select>
                </div>
              </div>
              <div
                class="col-xl-6 col-md-6 col-sm-6 col-6"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="form-group">
                  <label>Status</label>
                  <select
                    class="form-select"
                    v-model="Form_service_update.status"
                    aria-placeholder="Status"
                    placeholder="Status"
                    style="
                      height: 30px;
                      padding: 5px 5px 5px 5px;
                      border: 1px solid #00364f;
                    "
                  >
                    <option selected value="true">Active</option>
                    <option value="false">InActive</option>
                  </select>
                </div>
              </div>

              <div
                class="col-xl-12 col-md-12 col-sm-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <div class="from-group" style="margin-left: 5px">
                  <label style="color: #00364f;"
                    ><b>Government Fee type</b></label
                  >
                </div>
                <div class="row">
                  <div class="col-xl-6 col-md-6 col-sm-6 col-6">
                    <input
                      class="form-check-input"
                      value="fixed"
                      v-model="Form_service_update.gvtFeeType"
                      type="radio"
                      name="gvtFeesType"
                    />
                    <label class="form-check-label" style="margin-left: 5px">
                      Fixed
                    </label>
                  </div>
                  <div
                    class="col-xl-6 col-md-6 col-sm-6 col-6"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <input
                      class="form-check-input"
                      value="dynamic"
                      v-model="Form_service_update.gvtFeeType"
                      type="radio"
                      name="gvtFeesType"
                    />
                    <label class="form-check-label" style="margin-left: 5px">
                      Dynamic
                    </label>
                  </div>
                </div>
                <div
                  class="col-xl-12 col-md-12 col-sm-12 col-12"
                  style="padding: 5px 5px 5px 5px"
                >
                  <div class="from-group">
                    <textarea
                      class="form-control"
                      rows="3"
                      v-model="Form_service_update.remark"
                      placeholder="Remark"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn text-white btn-sm"
                style="background-color: #f21300; box-shadow: 0px 0px 5px 0px gray"
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f; box-shadow: 0px 0px 5px 0px gray"
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!--start model  Services Details-->
  <div
    class="modal font fade"
    id="editexample"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-white">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f; font-size: 1.4vw"
          >
            <b>Services Details</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <table class="table">
              <thead>
                <th></th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <th style="background-color: #cdcdcd; color: #00364f" scope="row">
                    Code
                  </th>
                  <td style="background-color: #f9f9f9; color: #00364f">
                    {{ servicesDetail.code }}
                  </td>
                </tr>
                <tr>
                  <th style="background-color: #cdcdcd; color: #00364f" scope="row">
                    Service
                  </th>
                  <td style="background-color: #f9f9f9; color: #00364f">
                    {{ servicesDetail.name }}
                  </td>
                </tr>
                <tr>
                  <th style="background-color: #cdcdcd; color: #00364f" scope="row">
                    VG-fees
                  </th>
                  <td style="background-color: #f9f9f9; color: #00364f">
                    {{ servicesDetail.superAdminFee }}
                  </td>
                </tr>
                <tr>
                  <th style="background-color: #cdcdcd; color: #00364f" scope="row">
                    professional. Fees
                  </th>
                  <td style="background-color: #f9f9f9; color: #00364f">
                    {{ servicesDetail.professionalFee }}
                  </td>
                </tr>
                <tr>
                  <th style="background-color: #cdcdcd; color: #00364f" scope="row">
                    Admin Fees
                  </th>
                  <td style="background-color: #f9f9f9; color: #00364f">-------</td>
                </tr>
                <tr>
                  <th style="background-color: #cdcdcd; color: #00364f" scope="row">
                    Govt Fees
                  </th>
                  <td style="background-color: #f9f9f9; color: #00364f">
                    {{ servicesDetail.governmentFee }}
                  </td>
                </tr>
                <tr>
                  <th style="background-color: #cdcdcd; color: #00364f" scope="row">
                    Maximum Member
                  </th>
                  <td style="background-color: #f9f9f9; color: #00364f">
                    {{ servicesDetail.maxMember }}
                  </td>
                </tr>
                <tr>
                  <th style="background-color: #cdcdcd; color: #00364f" scope="row">
                    Minimum Member
                  </th>
                  <td style="background-color: #f9f9f9; color: #00364f">
                    {{ servicesDetail.minMember }}
                  </td>
                </tr>
                <tr>
                  <th style="background-color: #cdcdcd; color: #00364f" scope="row">
                    Remark
                  </th>
                  <td style="background-color: #f9f9f9; color: #00364f">
                    {{ servicesDetail.remark }}
                  </td>
                </tr>
                <tr>
                  <th style="background-color: #cdcdcd; color: #00364f" scope="row">
                    Status
                  </th>
                  <td style="background-color: #f9f9f9; color: #00364f">
                    {{ servicesDetail.status }}
                  </td>
                </tr>

                <tr>
                  <th style="background-color: #cdcdcd; color: #00364f" scope="row">
                    Government Fees Type
                  </th>
                  <td style="background-color: #f9f9f9; color: #00364f">
                    {{ servicesDetail.gvtFeeType }}
                  </td>
                </tr>
                <tr>
                  <th style="background-color: #cdcdcd; color: #00364f" scope="row">
                    Professional Gst Required
                  </th>
                  <td style="background-color: #f9f9f9; color: #00364f">
                    {{ servicesDetail.professionalGstRequired }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="modal-footer">
              <button
                type="button"
                class="btn text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white"
                style="background-color: #00364f"
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->
  <!--start model Add-ons  -->
  <div
    class="modal font fade"
    id="addones"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" >
        <div class="modal-header bg-white" >
          <h4
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f;"
          >
            <b>Service Modules</b>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Link
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Create
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <!-- <form @submit.prevent="createServieModuleCharge"> -->
              <div class="container-fluid table-scroll">
                <table class="table table-hover table-sm">
                  <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                    <!-- <th style="width: 5%">&nbsp;+&nbsp;&nbsp;&nbsp;</th> -->
                    <th class="text-truncate">Module</th>
                    <th class="text-truncate">Gst Required&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th class="text-truncate">Rate&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <th class="text-truncate">Action </th>
                  </tr>

                  <tbody>
                    <tr
                      style="color: #00364f"
                      v-for="(modules, index) in servicemodules"
                      :key="index"
                    >
                      <!-- <td>
                          <input
                            type="checkbox"
                            @click="
                              check(modules.service_module_charge_id, index, $event)
                            "
                            v-model="form.id"
                            :value="modules.id"
                            id="flexCheckDefault"
                          />
                        </td> -->
                      <td class="text-truncate">
                        <span> {{ modules.moduleName }}</span>
                      </td>

                      <td>
                        <!-- :value="modules.gstRequired" -->
                        <!-- <input :value="form.gstRequired" @click="gstRequiredValue($event)" type="checkbox"  style=";height:30px;"> -->
                        <!-- @change="gstReqForModule(index)" -->
                        <!-- :value="modules.gstRequired" -->
                        <!-- v-model="createServiceModuleForm.gstRequired" -->
                        <select
                          @click="gstReqForModule($event)"
                          :value="modules.gstRequired"
                          class="form-select"
                          aria-label="Default select example"
                          style="height: 30px; padding: 5px 5px 5px 5px"
                        >
                          <option value="true">Yes</option>
                          <option value="false" selected>No</option>
                        </select>

                        <!-- <input
                            type="text"
                            class="form-control"
                            :value="modules.gstRequired"
                            @change="gstReqForModule($event, index)"
                            style="height: 30px"
                            placeholder="Rs.0000"
                          /> -->
                      </td>

                      <td>
                        <!-- @change="amountForModule($event, index, modules)" -->

                        <!-- v-model="createServiceModuleForm.amt" -->
                        <input
                          type="number"
                          class="form-control"
                          :value="modules.amt"
                          style="height: 30px"
                          placeholder="Rs.0000"
                          @change="amountForModule($event, index, modules)"
                        />
                      </td>
                      <td class="text-end">
                        <!-- <button
                          @click="createServieModuleCharge(modules.id)"
                          class="btn btn-sm"
                          type="button"
                          style="
                            background-color: #00364f;
                            color: white;
                            padding: 5px 5px 5px 5px;
                          "
                        >
                          <i class="fa fa-floppy-o"></i>
                        </button> -->
                        <div class="btn-group btn-group-sm" role="group">
                          <button
                            type="button"
                            @click="createServieModuleCharge(modules.id)"
                            class="btn btn-sm text-white"
                            style="
                              padding: 5px 4.5px 5px 4.5px;
                              background-color: #00364f;
                            "
                          >
                            <i class="fa fa-floppy-o"></i>
                          </button>

                          <button
                            v-if="modules.amt"
                            type="button"
                            @click="
                              deleteServiceModuleChagesNew(
                                modules.service_module_charge_id
                              )
                            "
                            class="btn btns btn-sm btn-danger"
                            style="padding: 5px 4.5px 5px 4.5px"
                          >
                            <font-awesome-icon icon="trash" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn text-white btn-sm"
                  style="background-color: #f21300"
                  data-bs-dismiss="modal"
                >
                  <b>Cancel</b>
                </button>
              </div>
              <!-- </form> -->
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <form
                id="FormID"
                @submit.prevent="
                  formModuleedit == 'false' ? saveModules() : updateModules()
                "
              >
                <div class="row">
                  <div class="col" style="padding: 5px 5px 5px 5px">
                    <div class="form-group">
                      <label><strong>Name <small style="color:red">*</small></strong></label>
                      <input
                      required

                        type="text"
                        class="form-control"
                        placeholder="Name"
                        v-model="formModuleSave.moduleName"
                        @change="lenthheker($event, 'namemodule')"
                        style="height: 30px; "
                      />
                      <span style="color: #f21000" id="namemodule"></span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col" style="padding: 5px 5px 5px 5px">
                    <div class="form-group">
                      <label><strong>Type</strong></label>
                      <select
                        class="form-select"
                        v-model="formModuleSave.type"
                        style="
                          padding: 5px 5px 5px 10px;
                          height: 30px;
                          ;
                        "
                      >
                        <option value="addon" selected>Addon</option>
                        <option value="module">Module</option>
                      </select>
                    </div>
                  </div>
                  <div class="col" style="padding: 5px 5px 5px 5px">
                    <div class="form-group">
                      <label><strong>Status</strong></label>
                      <select
                        class="form-select"
                        v-model="formModuleSave.status"
                        style="
                          padding: 5px 5px 5px 10px;
                          height: 30px;
                          ;
                        "
                      >
                        <option value="true" selected>Active</option>
                        <option value="false">InActive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col" style="padding: 5px 5px 5px 5px">
                    <div class="form-group">
                      <label><strong>Remark</strong></label>
                      <textarea
                        v-model="formModuleSave.remark"
                        class="form-control"
                        rows="3"
                        placeholder="remark"
                        
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn text-white btn-sm"
                    style="background-color: red"
                    data-bs-dismiss="modal"
                  >
                    <b>Close</b>
                  </button>
                  <button
                    type="submit"
                    class="btn text-white btn-sm"
                    style="background-color: #00364f"
                  >
                    <strong>Confirm</strong>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->

  <!--start model GovtFees  -->
  <div
    class="modal font fade"
    id="GovtFees"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content" >
        <div class="modal-header bg-white" >
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f;"
          >
            <b>Government Fees</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" >
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                :class="`nav-link ${isActive}`"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
           <span v-if="this.gvtFeesTypeCompare == 'dynamic'">  State Wise</span>   
           <span v-else>Fixed </span>
              </button>
              <button
                class="nav-link ative"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Member Wise
              </button>
              <button
                class="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                Member Addon
              </button>
              <button
                class="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-other"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                Other Fees
              </button>
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div v-if="this.gvtFeesTypeCompare == 'dynamic'">
                <div class="text-center">
                  <h4 style="color: #00364f; font-weight: 600">State Wise</h4>
                </div>
                <div class="container-fluid table-scroll" style="margin-left: -5px">
                  <table class="table table-hover table-sm">
                    <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                      <th class="text-truncate" >sn.</th>
                      <th class="text-truncate" >State</th>
                      <th class="text-truncate" >Up to 1 Lakhs</th>
                      <th class="text-truncate" >Up to 5 Lakhs</th>
                      <th class="text-truncate" >Up to 10 Lakhs</th>
                      <th class="text-truncate" >Up to 15 Lakhs</th>
                    </tr>

                    <tbody>
                      <!-- for five recprd eshoe (state, index) in allstates.slice(start, end) -->
                      <tr
                        style="color: #00364f"
                        v-for="(state, index) in allstates"
                        :key="index"
                      >
                        <td>
                          {{ index + start + 1 }}
                        </td>
                        <td class="text-truncate">
                          {{ state.name }}
                        </td>
                        <td class="text-truncate">
                          <input
                            type="number"
                            :value="valueChekerStateCharges(state.service_charges, '1')"
                            @change="stateServiceChargeSave(state, 1, $event)"
                            class="form-control"
                            style="height: 30px; width: 100%"
                          />
                        </td>
                        <td class="text-truncate">
                          <input
                            type="number"
                            :value="valueChekerStateCharges(state.service_charges, '5')"
                            @change="stateServiceChargeSave(state, 5, $event)"
                            class="form-control"
                            style="height: 30px; width: 100%"
                          />
                        </td>
                        <td class="text-truncate">
                          <input
                            type="number"
                            :value="valueChekerStateCharges(state.service_charges, '10')"
                            @change="stateServiceChargeSave(state, 10, $event)"
                            class="form-control"
                            style="height: 30px; width: 100%"
                          />
                        </td>
                        <td class="text-truncate">
                          <input
                            type="number"
                            :value="valueChekerStateCharges(state.service_charges, '15')"
                            @change="stateServiceChargeSave(state, 15, $event)"
                            class="form-control"
                            style="height: 30px; width: 100%"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <div class="text-end">
                    <button
                      type="button"
                      class="btn btn-sm text-white mt-1"
                      @click="lastFiveState(start, end)"
                      style="background-color: #00364f"
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm text-white mt-1"
                      @click="nextFiveState(start, end)"
                      style="background-color: #00364f; margin-left: 5px"
                    >
                      Next
                    </button>
                  </div> -->
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-xl-3 col-md-3 col-12">
                    <div class="input-group">
                      <input
                        type="number"
                        v-model="Form_service_update.governmentFee"
                        class="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                      />
                      <button
                        type="button"
                        @click="saveGvtFees"
                        class="input-group-text text-white"
                        id="inputGroup-sizing-default"
                        style="background-color: #00364f"
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <form
                @submit.prevent="
                  memberWiseFeeEdit == 'false'
                    ? memberWiseFeeSaveData()
                    : memberWiseFeeUpdateData()
                "
              >
                <div class="row">
                  <div class="col-12 text-start">
                    <h4 style="color: #00364f; font-weight: 600">Member wise</h4>
                  </div>
                </div>
                <div
                  class="container-fluid table-scroll"
                  style="margin-left: -5px; margin-top: 10px"
                >
                  <table class="table table-hover table-sm">
                    <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                      <th class="text-truncate">Members &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                      <th class="text-truncate">Charge &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                      <th class="text-truncate">Gst Required &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                      <th class="text-truncate">Status &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                      <th class="text-truncate">Action </th>
                    </tr>

                    <tbody>
                      <tr
                        style="color: #00364f"
                        v-for="(memCharge, index) in servicememberchargesLoaded"
                        :key="index"
                      >
                      
                        <td class="text-truncate">
                          <b>{{ memCharge.member }}</b>
                        </td>
                        <td class="text-truncate">
                          <b>{{ memCharge.memberFee }}</b>
                        </td>
                        <td class="text-start">
                          <p
                            v-if="memCharge.gstRequired == 'true'"
                            class="btn btn-sm text-white gtsApp"
                            style="background-color: green; padding: 5px 7px 5px 7px"
                          >
                            <b>
                              <font-awesome-icon icon="check" />
                            </b>
                          </p>
                          <p
                            v-else
                            class="btn btn-sm text-white gtsApp"
                            style="background-color: red; padding: 5px 8px 5px 8px"
                          >
                            <b>
                              <font-awesome-icon icon="remove" />
                            </b>
                          </p>
                        </td>
                        <td class="text-start">
                          <p
                            v-if="memCharge.status == 'true'"
                            class="btn btn-sm text-white gtsApp"
                            style="background-color: green; padding: 5px 7px 5px 7px"
                          >
                            <b>
                              <font-awesome-icon icon="check" />
                            </b>
                          </p>
                          <p
                            v-else
                            class="btn btn-sm text-white gtsApp"
                            style="background-color: red; padding: 5px 8px 5px 8px"
                          >
                            <b>
                              <font-awesome-icon icon="remove" />
                            </b>
                          </p>
                        </td>
                        <td>
                          <div class="btn-group btn-group-sm text-end" role="group">
                            <button
                              type="button"
                              @click="memberWiseFeeValueAssignData(memCharge)"
                              class="btn text-white btn-sm"
                              style="background-color: green; padding: 5px 5px 5px 5px"
                            >
                              <b>
                                <font-awesome-icon icon="edit" />
                              </b>
                            </button>
                            <button
                              type="button"
                              @click="memberWiseFeeDelete(memCharge)"
                              class="btn btns btn-sm btn-danger"
                              style="padding: 5px 4.5px 5px 4.5px"
                            >
                              <font-awesome-icon icon="trash" />
                            </button>
                          </div>
                        </td>
                      </tr>

                      <tr style="color: #00364f">
                      
                        <td>
                          <!-- @change="chakevalidation(memberWiseFee_form.member)" -->
                          <select
                            style="height: 30px; width: 80%; padding-top: 2px"
                            v-model="memberWiseFee_form.member"
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                          <span style="color: #f21000" id="memberWiseError"></span>
                          <small style="color: red" v-if="memberWiseFee_form.errors"
                            ><span v-if="memberWiseFee_form.errors.error">
                              {{ memberWiseFee_form.errors.error }}
                            </span></small
                          >
                        </td>
                        <td>
                          <input
                            type="number"
                            class="form-control"
                            v-model="memberWiseFee_form.memberFee"
                            placeholder="Member Feec "
                            style="height: 30px; width: 80%"
                            required
                          />
                        </td>
                        <td>
                          <!-- <input type="text" class="form-control" v-model="memberWiseFee_form.gstRequired"
                          placeholder="Member Feec " style="height:30px;width:80%"> -->

                          <select
                            style="height: 30px; width: 80%; padding-top: 2px"
                            v-model="memberWiseFee_form.gstRequired"
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option value="true">Yes</option>
                            <option value="false" selected>No</option>
                          </select>
                        </td>
                        <td class="text-center">
                          <select
                            style="height: 30px; width: 80%; padding-top: 2px"
                            v-model="memberWiseFee_form.status"
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option value="true" selected>Active</option>
                            <option value="false">InActive</option>
                          </select>
                        </td>
                        <td>
                          <div class="col-6 text-end">
                            <button
                              v-if="memberWiseError == 'true'"
                              type="button"
                              class="btn text-white btn-sm"
                              style="background-color: #2896c9; padding: 5px 5px 5px 5px"
                            >
                              <b>
                                <font-awesome-icon icon="floppy-disk" />
                              </b>
                            </button>
                            <button
                              v-else
                              type="submit"
                              class="btn text-white btn-sm"
                              style="background-color: #00364f; padding: 5px 5px 5px 5px"
                            >
                              <b>
                                <font-awesome-icon icon="floppy-disk" />
                              </b>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
            <div
              class="tab-pane fade"
              id="nav-contact"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
            >
              <div class="text-center">
                <h4 style="color: #00364f; font-weight: 600">Member Addon Charges</h4>
              </div>
              <div class="container-fluid table-scroll" style="margin-left: -5px">
                <form>
                  <div
                    class="container-fluid table-scroll"
                    style="margin-left: -5px; margin-top: 10px"
                  >
                    <table class="table table-hover table-sm">
                      <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                        <th class="text-start" style="width: 3%">
                          S.No.
                        </th>
                        <th class="text-start" style="width: 15%">
                          Addons
                        </th>
                        <th>1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th>10&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                         <th class="text-start text-truncate" style="width: 15%">
                          Gst Required&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </th>
                        <th class="text-start text-truncate" style="width: 3%">
                          Save&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </th>
                      </tr>

                      <tbody>
                        <tr
                          style="color: #00364f"
                          v-for="(memberAddon, index) in memberAddons"
                          :key="index"
                        >
                          <td>
                            <b>{{ index + 1 }}</b>
                          </td>
                          <td class="text-truncate">
                            <b>{{ memberAddon.title }}</b>
                          </td>
                          <td>
                            <input
                              type="number"
                              :value="
                                valueCheckerMemberFees(
                                  memberAddon.service_member_addons,
                                  '1'
                                )
                              "
                              @change="
                                membereAddonesValueOfMember(memberAddon, 1, $event)
                              "
                              class="form-control"
                              style="height: 28px; padding: 2px 2px 2px 2px"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              :value="
                                valueCheckerMemberFees(
                                  memberAddon.service_member_addons,
                                  '2'
                                )
                              "
                              @change="
                                membereAddonesValueOfMember(memberAddon, 2, $event)
                              "
                              class="form-control"
                              style="height: 28px; padding: 2px 2px 2px 2px"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              :value="
                                valueCheckerMemberFees(
                                  memberAddon.service_member_addons,
                                  '3'
                                )
                              "
                              @change="
                                membereAddonesValueOfMember(memberAddon, 3, $event)
                              "
                              class="form-control"
                              style="height: 28px; padding: 2px 2px 2px 2px"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              :value="
                                valueCheckerMemberFees(
                                  memberAddon.service_member_addons,
                                  '4'
                                )
                              "
                              @change="
                                membereAddonesValueOfMember(memberAddon, 4, $event)
                              "
                              class="form-control"
                              style="height: 28px; padding: 2px 2px 2px 2px"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              :value="
                                valueCheckerMemberFees(
                                  memberAddon.service_member_addons,
                                  '5'
                                )
                              "
                              @change="
                                membereAddonesValueOfMember(memberAddon, 5, $event)
                              "
                              class="form-control"
                              style="height: 28px; padding: 2px 2px 2px 2px"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              :value="
                                valueCheckerMemberFees(
                                  memberAddon.service_member_addons,
                                  '6'
                                )
                              "
                              @change="
                                membereAddonesValueOfMember(memberAddon, 6, $event)
                              "
                              class="form-control"
                              style="height: 28px; padding: 2px 2px 2px 2px"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              :value="
                                valueCheckerMemberFees(
                                  memberAddon.service_member_addons,
                                  '7'
                                )
                              "
                              @change="
                                membereAddonesValueOfMember(memberAddon, 7, $event)
                              "
                              class="form-control"
                              style="height: 28px; padding: 2px 2px 2px 2px"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              :value="
                                valueCheckerMemberFees(
                                  memberAddon.service_member_addons,
                                  '8'
                                )
                              "
                              @change="
                                membereAddonesValueOfMember(memberAddon, 8, $event)
                              "
                              class="form-control"
                              style="height: 28px; padding: 2px 2px 2px 2px"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              :value="
                                valueCheckerMemberFees(
                                  memberAddon.service_member_addons,
                                  '9'
                                )
                              "
                              @change="
                                membereAddonesValueOfMember(memberAddon, 9, $event)
                              "
                              class="form-control"
                              style="height: 28px; padding: 2px 2px 2px 2px"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              :value="
                                valueCheckerMemberFees(
                                  memberAddon.service_member_addons,
                                  '10'
                                )
                              "
                              @change="
                                membereAddonesValueOfMember(memberAddon, 10, $event)
                              "
                              class="form-control"
                              style="height: 28px; padding: 2px 2px 2px 2px"
                            />
                          </td>
                          <td>
                            <select
                              :value="
                                gstStatusReturnMemberAddon(
                                  memberAddon.service_member_addons
                                )
                              "
                              style="height: 28px; padding: 2px 2px 2px 2px"
                              @change="
                                memberAddonChargesGstRequiredSave($event, memberAddon)
                              "
                              required
                              class="form-select"
                              aria-label="Default select example"
                            >
                              <option value="false" selected>No</option>
                              <option value="true">Yes</option>
                            </select>
                          </td>
                          <td>
                            <div class="col-6 text-end">
                              <button
                                @click="addonGstSave(memberAddon)"
                                type="button"
                                class="btn text-white btn-sm"
                                style="
                                  background-color: #00364f;
                                  padding: 5px 5px 5px 5px;
                                "
                              >
                                <b>
                                  <font-awesome-icon icon="floppy-disk" />
                                </b>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="nav-other"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
            >
              <form
                @submit.prevent="
                  editOtherFees == 'false' ? otherFeesSave() : updateGvtFeesOther()
                "
              >
                <div class="row">
                  <div class="col-12 text-start">
                    <h4 style="color: #00364f; font-weight: 600">Other Fees</h4>
                  </div>
                </div>
                <div
                  class="container-fluid table-scroll"
                >
                  <table class="table table-hover table-sm">
                    <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                      <th class="text-start " >
                        S.No.
                      </th>
                      <th class="text-start text-truncate">Particular &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                      <th
                        class="text-start text-truncate"
                        style=" margin-left: 15px"
                      >
                        Rate &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </th>
                      <th class="text-end text-truncate">
                        Gst Applicable&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </th>
                      <th class="text-end text-truncate">
                        Action&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </th>
                     </tr>

                    <tbody>
                      <tr
                        style="color: #00364f"
                        v-for="(otherFee, index) in otherFeesInfo"
                        :key="index"
                      >
                        <td>
                          <b>{{ index + 1 }}</b>
                        </td>
                        <td class="text-truncate">
                          <b>{{ otherFee.title }}</b>
                        </td>
                        <td class="text-truncate">
                          <b>{{ otherFee.amt }}</b>
                        </td>
                        <td class="text-center">
                          <p
                            class="btn btn-sm text-white gtsApp"
                            v-if="otherFee.gstRequired == 'true'"
                            style="background-color: green; padding: 5px 7px 5px 7px"
                          >
                            <b>
                              <font-awesome-icon icon="check" />
                            </b>
                          </p>
                          <p
                            class="btn btn-sm text-white gtsApp"
                            v-if="otherFee.gstRequired == 'false'"
                            style="background-color: red; padding: 5px 8px 5px 8px"
                          >
                            <b>
                              <font-awesome-icon icon="remove" />
                            </b>
                          </p>
                        </td>
                        <td>
                          <div class="btn-group btn-group-sm text-end" role="group">
                            <button
                              type="button"
                              @click="editGvtFeesOther(otherFee)"
                              class="btn text-white btn-sm"
                              style="background-color: green; padding: 5px 5px 5px 5px"
                            >
                              <b>
                                <font-awesome-icon icon="edit" />
                              </b>
                            </button>
                            <button
                              type="button"
                              @click="otherFeesDelete(otherFee)"
                              class="btn btns btn-sm btn-danger"
                              style="padding: 5px 4.5px 5px 4.5px"
                            >
                              <font-awesome-icon icon="trash" />
                            </button>
                          </div>
                        </td>
                      </tr>

                      <tr style="color: #00364f">
                        <td>
                          <img
                            style="width: 10px"
                            src="/assets/image/record.png"
                            alt=""
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            v-model="otherFees.title"
                            placeholder="Cost Name"
                            style="height: 30px; width: 80%"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            class="form-control"
                            v-model="otherFees.amt"
                            placeholder="Rate"
                            style="height: 30px; margin-right: -35px"
                          />
                        </td>
                        <td class="text-center">
                          <!-- <input
                            class="form-check-input"
                            type="checkbox"
                            v-model="otherFees.gstRequired"
                            id="gst"
                          /> -->
                          <select
                            style="height: 30px; padding: 5px 5px 5px 5px"
                            class="form-select"
                            aria-label="Default select example"
                            v-model="otherFees.gstRequired"
                          >
                            <option value="true">Yes</option>
                            <option value="false" selected>No</option>
                          </select>
                        </td>
                        <td>
                          <div class="col-6 text-end">
                            <div class="btn-group btn-group-sm text-end" role="group">
                              <button
                                type="submit"
                                class="btn text-white btn-sm"
                                style="
                                  background-color: #00364f;
                                  padding: 5px 5px 5px 5px;
                                "
                              >
                                <b>
                                  <font-awesome-icon icon="floppy-disk" />
                                </b>
                              </button>
                              <button
                                @click="newrecordAdd()"
                                type="button"
                                class="btn text-white btn-sm"
                                style="background-color: #f21000; padding: 5px 5px 5px 5px"
                              >
                                <b> C </b>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>

          <!--  -->

          <div class="modal-footer">
            <!-- <button type="button" class="btn text-white" style="background-color:#f21300;"
              data-bs-dismiss="modal"><b>Cancel</b></button>
            <button type="submit" class="btn text-white" data-bs-dismiss="modal"
              style="background-color:#00364f;"><b>Confirm</b></button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->

  <!-- faqs0 -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="faqs"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong class="text">FAQ's </strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="faqEdit == 'false' ? faqSave() : faqUpdate()">
            <div class="row">
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label><strong class="text">FAQ's Title <small style="color:#f21000">*</small></strong></label>
                <input
                required 
                  type="text"
                  style="padding: 5px 5px 5px 5px; height: 30px"
                  v-model="faqForm.title"
                  class="form-control"
                  placeholder="Title"
                />
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label><strong class="text">Embed Video</strong></label>
                <input
                  style="padding: 5px 5px 5px 5px; height: 30px"
                  type="text"
                  v-model="faqForm.embedVideo"
                  class="form-control"
                  placeholder="Embed Video"
                />
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label><strong class="text">Description</strong></label>
                <textarea
                  v-model="faqForm.description"
                  id="aboutCampaign"
                  rows="3"
                  class="form-control"
                  placeholder="Description"
                ></textarea>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button>
            </div>
          </form>

          <div class="row">
            <div class="col">
              <table class="table table-hover table-sm">
                <tr
                  class="text table-wrapper-scroll-y rounded-circle tr-head"
                  style="background-color: white"
                >
                  <th
                    class="text-truncate"
                    style="background-color: #cdcdcd; border-radius: 8px 0px 0px 8px"
                  >
                    Title
                  </th>
                  <th class="text-truncate" style="background-color: #cdcdcd">
                    Description
                  </th>
                  <th
                    class="text-truncate text-end"
                    style="background-color: #cdcdcd; border-radius: 0px 8px 8px 0px"
                  >
                    Action
                  </th>
                </tr>

                <tbody>
                  <tr v-for="(faq, index) in faqsRecords" :key="index">
                    <td>{{ faq.title }}</td>
                    <td>{{ faq.description }}</td>
                    <td class="text-end">
                      <div class="btn-group btn-group-sm" role="group">
                        <button
                          class="btn btn-sm text-white"
                          style="background-color: green; padding: 5px 5px 5px 5px"
                          @click="faqEditData(faq)"
                        >
                          <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </button>
                        <button
                          class="btn btn-sm text-white"
                          style="background-color: #f21000; padding: 5px 5px 5px 5px"
                          @click="deleteFaq(faq)"
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <!-- <button
                class="nav-link active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-faq"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                FAQ,s
              </button> -->
              <!-- <button
                class="nav-link"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-video"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                Video
              </button> -->
              <!-- <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button> -->
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-faq"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            ></div>
            <div
              class="tab-pane fade"
              id="nav-video"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <form
                @submit.prevent="
                  videoFaqFormEdit == 'false' ? videoFaqFormSave() : videoFaqFormUpdate()
                "
              >
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Title</strong></label>
                    <input
                      type="text"
                      v-model="videoFaqForm.title"
                      class="form-control"
                      placeholder="Title"
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">SEO Title</strong></label>
                    <input
                      type="text"
                      v-model="videoFaqForm.seoTitle"
                      class="form-control"
                      placeholder="Title"
                    />
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Meta Keywords</strong></label>
                    <input
                      type="text"
                      v-model="videoFaqForm.metaKeywords"
                      class="form-control"
                      placeholder="Meta Keywords"
                    />
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Meta Description</strong></label>
                    <textarea
                      v-model="videoFaqForm.metaDescription"
                      id="Meta Description"
                      rows="2"
                      class="form-control"
                      placeholder="Meta Description"
                    ></textarea>
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Tags</strong></label>
                    <input
                      type="text"
                      v-model="videoFaqForm.tags"
                      class="form-control"
                      placeholder="Tags"
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Slugs</strong></label>
                    <input
                      type="text"
                      v-model="videoFaqForm.slugs"
                      class="form-control"
                      placeholder="slugs"
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Image</strong></label>
                    <input
                      type="text"
                      v-model="videoFaqForm.image"
                      class="form-control"
                      placeholder="Image"
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text"> Thumbnail</strong></label>
                    <input
                      type="text"
                      v-model="videoFaqForm.thumbnail"
                      class="form-control"
                      placeholder="thumbnail"
                    />
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Date</strong></label>
                    <input
                      type="date"
                      v-model="videoFaqForm.date"
                      class="form-control"
                      placeholder="Date"
                    />
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Visitors</strong></label>
                    <input
                      type="number"
                      v-model="videoFaqForm.visitors"
                      class="form-control"
                      placeholder="Visitors"
                    />
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Other</strong></label>
                    <input
                      type="text"
                      v-model="videoFaqForm.other"
                      class="form-control"
                      placeholder="Other"
                    />
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Note</strong></label>
                    <input
                      type="text"
                      v-model="videoFaqForm.note"
                      class="form-control"
                      placeholder="note"
                    />
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Embed Video</strong></label>
                    <input
                      type="text"
                      v-model="videoFaqForm.embedVideo"
                      class="form-control"
                      placeholder="Embed Video"
                    />
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Short Description</strong></label>
                    <textarea
                      v-model="videoFaqForm.shordDescription"
                      id="Meta Description"
                      rows="2"
                      class="form-control"
                      placeholder="Short Description"
                    ></textarea>
                  </div>
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label><strong class="text">Description</strong></label>
                    <textarea
                      v-model="videoFaqForm.description"
                      id="aboutCampaign"
                      rows="5"
                      class="form-control"
                      placeholder="Description"
                    ></textarea>
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-sm text-white"
                    style="background-color: #f21000"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn btn-sm text-white"
                    style="background-color: #00364f"
                  >
                    <strong>Confirm</strong>
                  </button>
                </div>
              </form>

              <div class="row">
                <div class="col">
                  <table class="table table-hover table-sm">
                    <tr
                      class="text table-wrapper-scroll-y rounded-circle tr-head"
                      style="background-color: white"
                    >
                      <th
                        class="text-truncate"
                        style="background-color: #cdcdcd; border-radius: 8px 0px 0px 8px"
                      >
                        Title
                      </th>
                      <th class="text-truncate" style="background-color: #cdcdcd">
                        Description
                      </th>
                      <th
                        class="text-truncate text-end"
                        style="background-color: #cdcdcd; border-radius: 0px 8px 8px 0px"
                      >
                        Action
                      </th>
                    </tr>

                    <tbody>
                      <tr v-for="(faqVideo, index) in faqVideosRecords" :key="index">
                        <td>{{ faqVideo.title }}</td>
                        <td>{{ faqVideo.description }}</td>
                        <td class="text-end">
                          <div class="btn-group btn-group-sm" role="group">
                            <button
                              class="btn btn-sm text-white"
                              style="background-color: green; padding: 5px 5px 5px 5px"
                              @click="faqVideoEditData(faqVideo)"
                            >
                              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                            </button>
                            <button
                              class="btn btn-sm text-white"
                              style="background-color: #f21000; padding: 5px 5px 5px 5px"
                              @click="deleteFaqVideo(faqVideo)"
                            >
                              <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>



    <!-- Right Sidebar starts -->
    <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



            <ul class="list-group list-group-flush">
              <li class="list-group-item"><div class="form-check">
  <input v-model="filterType" value="code" class="form-check-input" type="radio" name="flexRadioDefault" id="code">
  <label class="form-check-label" for="code">
  Code
  </label>
</div></li>

  <li class="list-group-item"><div class="form-check">
  <input v-model="filterType" value="name" class="form-check-input" type="radio" name="flexRadioDefault" id="name">
  <label class="form-check-label" for="name">
    Service Name
  </label>
</div></li>



<li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="all" class="form-check-input" type="radio" name="flexRadioDefault" id="all">
  <label class="form-check-label" for="all">
    All
  </label>
</div>
  </li>
</ul>


<div class="pt-1 pb-1">
  <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
 <!-- <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 <input type="text" v-if="filterType != 'all'" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <!-- <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 






</div>

<button  @click="getServices()" type="button" class="btn btn-success btn-sm">Success</button>



           
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->



</template>

<script>
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Form from "vform";
var _ = require("lodash");
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "superAdminPackageServices",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      isActive: false,


      filterValue:'',
      filterType:'',
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page:10,
      formStepCreateedit: "false",
      formStepCreate: {
        title: "",
        status: "true",
        remark: "",
        errors: {},
      },
      formModuleSave: {
        moduleName: "",
        type: "addon",
        status: "true",
        remark: "",
      },
      formModuleedit: "false",
      memberWiseError: "false",
      msgImgError: "",
      loading: false,
      serviceIconImg: this.$store.state.placeholderImg,
      steps: [],
      logo: "",
      memberAddons: [],
      service_id: "",
      serviceGvt: {},
      editOtherFees: "false",
      start: 0,
      end: 5,
      allstates: [],
      stateServiceCharges: [],
      packages: [],
      services: [],
      allServices: [],
      moduleInfo: [],
      servicemodulecharges: [],
      servicemodules: [],
      createServiceModuleForm: {
        amt: "",
        service_id: null,
        service_module_id: null,
        gstRequired: "false",
        status: "true",
      },
      servicesDetail: {},
      faqsRecords: [],
      faqEdit: "false",
      faqForm: {
        service_id: null,
        title: "",
        description: "",
        embedVideo: "",
        type: "faq",
        status: "true",
        remark: "",
      },
      faqVideosRecords: [],
      videoFaqFormEdit: "false",
      videoFaqForm: {
        super_admin_id: null,
        service_id: null,
        title: "",
        seoTitle: "",
        metaKeywords: "",
        metaDescription: "",
        tags: "",
        slugs: "",
        image: "",
        thumbnail: "",
        embedVideo: "",
        shordDescription: "",
        description: "",
        date: "",
        note: "",
        other: "",
        visitors: "",
        remark: "",
        status: "true",
        ipAddress: "",
      },
      // for module
      serviceCharges: [],
      form: new Form({
        amt: null,
        gstRequired: "true",
        id: [],
        serviceId: null,
      }),
      stateServiceChargeForm: {
        service_id: null,
        state_id: null,
        governmentFee: null,
        authrizationCapital: "",
      },
      serviceSteps: [],
      edit: "false",
      form_step_update_id: null,
      form_step: {
        service_id: this.service_id,
        priority: "",
        step_id: null,
        remark: "",
        status: "true",
        errors: {},
      },
      form_Docc: {
        service_id: null,
        document: "",
        size: "2048",
        format: "jpg,png,jpeg,pdf",
        height: "",
        width: "",
        thumbnail: "false",
        remark: "",
        status: "true",
      },
      DoccVal: "false",
      allDoccs: [],
      gvtFeesTypeCompare: "",
      otherFeesInfo: [],
      otherFees: {
        gstRequired: "false",
        title: "",
        amt: null,
        service_id: null,
        status: "Active",
      },

      Form_service_update_id: null,
      Form_service_update: {
        code: null,
        logo: "",
        name: "",
        minMember: "",
        maxMember: "",
        governmentFee: "",
        gvtFeeType: "",
        minCapital: "",
        superAdminFee: "",

        adminFee: "",
        professionalFee: "",
        professionalGstRequired: "false",
        superAdminFeeGstRequired: "false",
        adminFeeGstRequired: "false",
        status: "false",
        remark: "",
        errors:{}
      },
      memberWiseFee_form: {
        service_id: null,
        memberFee: "",
        gstRequired: "false",
        member: "",
        status: "true",

        remark: "",
        errors: {},
      },
      namemodule: "false",
      servicememberchargesLoaded: [],
      memberWiseFeeEdit: "false",
      gstRequiredValues_form: {
        service_id: null,
        member_addon_id: null,
        gstRequired: "false",
      },
      servicemodulechargesForm: {
        serviceCharges: [],
      },
      minmamValidation: "false",
      maxmamValidation: "false",
    };
  },

  methods: {
    clearStepForm(){
        this.form_step.step_id = ""
        this.form_step.priority = ""
        this.edit = "false"


        this.msgImgError=""

        this.form_step = {}

    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.getServices();
    },
    stepsNewCreateForStep() {
      // if (this.stepname == "false") {
      this.loading = true;
      var formData = this.formStepCreate;
      formData.status = "true";

      this.$axios
        .post("superadmin/steps", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Step Created  Succesfully", {
            autoClose: 1000,
          });
          this.formStepCreate = {};
          this.formStepCreate.errors = {};
          this.getStepsForservices();
        })

        .catch((error) => {
          this.formStepCreate.errors = error.response.data;
        })
        .finally(() => (this.loading = false));
      // }
    },
    updateModules() {
      if (this.namemodule == "false") {
        this.loading = true;
        this.$axios
          .put(
            `superadmin/servicemodule/${this.formModuleSave.id}`,
            this.formModuleSave,
            {
              headers: { Authorization: "Bearer " + localStorage.accessToken },
            }
          )
          .then((res) => {
            console.log(res.data.data.data);

            toast.success("Module Update Succesfully", {
              autoClose: 1000,
            });
            this.formModuleSave = {};
            this.getServiceModulesNew();
            $("#exampleModal").modal("hide");
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            console.log(error);
          });
      }
    },
    modulesEdit(modules) {
      this.form = modules;
      this.edit = "true";
      // console.log(modules)
    },

    saveModules() {
      if (this.namemodule == "false") {
        this.loading = true;
        this.$axios
          .post("superadmin/servicemodule", this.formModuleSave, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            console.log(res.data);

            toast.success("Module Create Succesfully", {
              autoClose: 1000,
            });
            this.formModuleSave = {};
            this.formModuleSave.status = "true";
            this.formModuleSave.type = "addon";
            this.getServiceModulesNew();
            // $("#exampleModal").modal("hide");
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            console.log(error);
          });
      }
    },
    newrecordAdd() {
      this.editOtherFees = "false";
      this.otherFees = {};

      this.otherFees.status = "false";
      this.otherFees.service_id = this.service_id;
      // this.otherFees.amt = null;
      // this.otherFees.gstRequired = "false";
      // this.editOtherFees = "false";
    },
    gstStatusReturnMemberAddon(addon) {
      var data;

      for (var i = 0; i < addon.length; i++) {
        if (addon[i].gstRequired == "true") {
          data = "true";
        } else {
          data = "false";
        }
      }

      return data;
    },
    minMemberVal(event, type) {
      var data = event.target.value;
      console.log(data);
      if (type == "minmamValidation") {
        if (data < 1) {
          this.minmamValidation = "true";
          $("#minmamValidation").html("<small>Minimum 1 Member  Required</small>");
        }
        if (data >= 1) {
          this.minmamValidation = "false";
          $("#minmamValidation").html("<small></small>");
        }
      }

      if (type == "maxmamValidation") {
        if (data > 15) {
          console.log(data.length);
          this.maxmamValidation = "true";
          $("#maxmamValidation").html("<small>Maximum 15 Member  Required</small>");
        }

        if (data == 0) {
          this.maxmamValidation = "false";
          $("#maxmamValidation").html("<small>At Least 1 Member Reqiured</small>");
        }
        if (data <= 15 && data > 0) {
          this.maxmamValidation = "false";
          $("#maxmamValidation").html("<small></small>");
        }
      }
    },
    deleteFaqVideo(value) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`superadmin/learningcenter/${value.id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;

                this.$swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Video Deleted  Succesfully",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getFaqsRecords();
              });
          }
        });
    },
    faqVideoEditData(faqVideoInfo) {
      this.videoFaqForm = faqVideoInfo;
      this.videoFaqFormEdit = "true";
    },
    getFaqsVideosRecords() {
      this.$axios
        .get(`superadmin/learningcenter?service_id=${this.service_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res.data;
          this.faqVideosRecords = res.data.data.data;
        });
    },
    videoFaqFormSave() {
      var data = JSON.parse(localStorage.getItem("userData"));
      this.videoFaqForm.super_admin_id = data.id;
      this.videoFaqForm.service_id = this.service_id;

      this.$axios
        .post(`superadmin/learningcenter`, this.videoFaqForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })

        .then((res) => {
          // console.log(res.data);
          res.data;
          this.videoFaqForm = {};
          // this.getFaqsRecords();
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Learning Center Video Upload Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      this.getFaqsVideosRecords();
    },
    videoFaqFormUpdate() {
      var data = JSON.parse(localStorage.getItem("userData"));
      this.videoFaqForm.super_admin_id = data.id;
      this.videoFaqForm.service_id = this.service_id;
      console.log(this.videoFaqForm);

      this.$axios
        .put(`superadmin/learningcenter/${this.videoFaqForm.id}`, this.videoFaqForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })

        .then((res) => {
          // console.log(res.data);
          res.data;
          this.videoFaqForm = {};
          this.getFaqsVideosRecords();
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Learning Center Video Updated Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    deleteFaq(value) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .delete(`superadmin/servicefaq/${value.id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;

                toast.success("FAQ Deleted  Succesfully ", {
                  autoClose: 1000,
                });
                this.getFaqsRecords();
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
                console.log(error);
              });
          }
        });
    },
    getFaqsRecords() {
      this.$axios
        .get(`superadmin/servicefaq?service_id=${this.service_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res.data;
          this.faqsRecords = res.data.data.data;
        });
    },
    faqEditData(faqInfo) {
      this.faqForm = faqInfo;
      this.faqEdit = "true";
    },
    getServiceInfo(service) {
      console.log(service);
      this.faqForm = {};
      this.service_id = service.id;
      // this.faqForm.service_id = service.id;
      this.getFaqsRecords();
      this.getFaqsVideosRecords();
    },
    faqSave() {
      this.faqForm.service_id = this.service_id;
      this.faqForm.type = "faq";
      this.faqForm.status = "true";
      this.loading = true;
      this.$axios
        .post(`superadmin/servicefaq`, this.faqForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })

        .then((res) => {
          // console.log(res.data);
          res.data;
          this.faqForm = {};
          toast.success("Service Document Save Succesfully ", {
            autoClose: 1000,
          });
          this.getFaqsRecords();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {

this.$swal.fire({
      position: "top-center",
      icon: "error",
      title: "<small>" + error.response.data.error + "</small>",
      showConfirmButton: true,
      confirmButtonColor: "#00364f",
    });

  });

    },

    faqUpdate() {
      this.faqForm.type = "faq";
      this.faqForm.status = "true";
      this.faqForm.service_id = this.service_id;
      this.loading = true;
      this.$axios
        .put(`superadmin/servicefaq/${this.faqForm.id}`, this.faqForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })

        .then((res) => {
          // console.log(res.data);
          res.data;
          this.faqForm = {};
          toast.success("Service FAQ Update Succesfully ", {
            autoClose: 1000,
          });
          this.getFaqsRecords();

          this.faqEdit = "false";
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error);
        });
    },

    servDocumentEdit(docc) {
      this.form_Docc = docc;
      this.DoccVal = "true";
    },
    servDocumentLoad(service) {
      this.form_Docc.document = "";
      this.form_Docc.format = "jpeg,png,jpg,pdf";
      this.form_Docc.size = "2048";
      this.form_Docc.width = "";
      this.form_Docc.height = "";
      this.form_Docc.height = "";
      this.form_Docc.remark = "";
      this.form_Docc.thumbnail = "false";
      this.form_Docc.service_id = service.id;
      // console.log(this.service)
      this.LoadDocuments();
    },
    LoadDocuments() {
      this.$axios
        .get(`superadmin/servicedocument?service_id=${this.form_Docc.service_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data.data.data);
          this.allDoccs = res.data.data.data;
        });
    },
    servDocumentUpdate() {
      this.loading = true;
      this.$axios
        .put(`superadmin/servicedocument/${this.form_Docc.id}`, this.form_Docc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res.data;

          toast.success("Service Document Update Succesfully", {
            autoClose: 1000,
          });
          this.form_Docc.document = "";
          this.form_Docc.format = "jpeg,png,jpg,pdf";
          this.form_Docc.size = "2048";
          this.form_Docc.width = "";
          this.form_Docc.height = "";
          this.form_Docc.height = "";
          this.form_Docc.remark = "";
          this.form_Docc.thumbnail = "false";
          this.DoccVal = "false";
          this.getServices();
          this.LoadDocuments();
        })
        .finally(() => (this.loading = false));
    },
    servDocumentSave() {
      this.loading = true;
      this.$axios
        .post(`superadmin/servicedocument`, this.form_Docc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res.data;

          toast.success("Service Document Save Succesfully ", {
            autoClose: 1000,
          });
          // this.form_docc = {};
          this.form_Docc.document = "";
          this.form_Docc.format = "jpeg,png,jpg,pdf";
          this.form_Docc.size = "2048";
          this.form_Docc.width = "";
          this.form_Docc.height = "";
          this.form_Docc.height = "";
          this.form_Docc.remark = "";
          this.form_Docc.thumbnail = "false";
          this.DoccVal = "false";
          this.getServices();
          this.LoadDocuments();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          error;
        });
    },
    serviceIconfind(service) {
      if (service.logo != null) {
        this.serviceIconImg =
          this.$store.state.imgUrl +
          "/service-icons/" +
          service.id +
          "/thumbs/" +
          service.logo;
      } else {
        this.serviceIconImg = this.$store.state.placeholderImg;
      }
      return this.serviceIconImg;
    },
    serviceIconImgUpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Logo Image Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#serviceLogo").value;
        this.extensionLogo = fileName.split(".").pop();
        if (
           this.extensionLogo == "png" ||
          this.extensionLogo == "jpeg" ||
          this.extensionLogo == "jpg" ||

          this.extensionLogo == "PNG" ||
          this.extensionLogo == "JPEG" ||
          this.extensionLogo == "JPG" 
       
        ) {
          this.serviceIconImg = URL.createObjectURL(e.target.files["0"]);
          this.Form_service_update.logo = e.target.files["0"];
          const formData = new FormData();
          const keys = Object.keys(this.Form_service_update);
          for (let n = 0; n < keys.length; n += 1) {
            if (this.Form_service_update[keys[n]] !== null) {
              formData.append(keys[n], this.Form_service_update[keys[n]]);
            }
          }
          this.$axios
            .post(`superadmin/service-logo/${this.service_id}`, formData, {
              headers: { Authorization: "Bearer " + localStorage.accessToken },
            })
            .then((res) => {
              // console.log(res.data);
              res.data;
              // this.$swal.fire({
              //   position: "top-end",
              //   icon: "success",
              //   title: "Service Icon Change Succesfully",
              //   showConfirmButton: false,
              //   timer: 1500,
              // });

              this.getServices();
            })
            .catch((error) => {
              error;
            });
        } else {
          this.msgImgError = "File Type Must JPG , PNG, JPEG";
        }
      }

      // this.serviceIconImg = URL.createObjectURL(e.target.files["0"]);
      // this.Form_service_update.logo = e.target.files["0"];
    },
    chakevalidation(data) {
      $("#memberWiseError").html("<small></small>");
      var collagction = [];
      var status = "fasle";
      collagction = this.servicememberchargesLoaded;

      console.log(collagction);
      for (var i = 0; i < collagction.length; i++) {
        if (collagction[i].member == data) {
          status = "true";
          $("#memberWiseError").html("<small>already exists</small>");
        }
      }

      this.memberWiseError = status;
    },
    memberWiseFeeUpdateData() {
      this.loading = true;
      this.$axios
        .put(
          `superadmin/servicemembercharge/${this.memberWiseFee_form.id}`,
          this.memberWiseFee_form,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log(res.data);
          res;

          toast.success("Member Wise Fees Update Succesfully ", {
            autoClose: 1000,
          });
          this.memberWiseFee_form = {};
          this.memberWiseFee_form.status = "true";
          this.memberWiseFee_form.gstRequired = "false";
          this.memberWiseFee_form.service_id = this.service_id;
          this.memberWiseFeeEdit = "false";
          // .finally(() => (this.loading = false));
          this.loadMemberCharges();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error);
        });
    },
    memberWiseFeeValueAssignData(memCharge) {
      this.memberWiseFee_form = memCharge;
      this.memberWiseFeeEdit = "true";
    },
    memberWiseFeeDelete(value) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .delete(`superadmin/servicemembercharge/${value.id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;

                toast.success("Other Fees Deleted  Succesfully ", {
                  autoClose: 1000,
                });
                this.loadMemberCharges();
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
                console.log(error);
              });
          }
        });
    },
    loadMemberCharges() {
      this.loading = true;
      this.$axios
        .get(`superadmin/servicemembercharge?all=all&service_id=${this.service_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.servicememberchargesLoaded = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    memberWiseFeeSaveData() {
      this.loading = true;
      this.$axios
        .post("superadmin/servicemembercharge", this.memberWiseFee_form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res.data;
          res.data;

          toast.success("Member Wise Fees Created Succesfully", {
            autoClose: 1000,
          });
          this.memberWiseFee_form = {};
          this.memberWiseFee_form.status = "true";
          this.memberWiseFee_form.gstRequired = "false";
          this.memberWiseFeeEdit = "false";
          this.memberWiseFee_form.service_id = this.service_id;
          this.loadMemberCharges();
          // .finally(() => (this.loading = false));
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // error;
          console.log(error.response);
          this.memberWiseFee_form.errors = error.response.data;
        });
    },

    // step link
    updateServiceSteps() {
      this.form_step.status = "true";
      this.loading = true;
      this.$axios
        .put(`superadmin/servicesteps/${this.form_step_update_id}`, this.form_step, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Service Step Updated Succesfully ", {
            autoClose: 1000,
          });
          this.form_step.priority = "";
          this.form_step.step_id = null;
          this.edit = "false";
          this.form_step.status = "";
          this.form_step.errors = {};
          this.getServiceSteps();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
          this.form_step.errors = error.response.data;

          this.$swal.fire({
                position: "top-center",
                icon: "error",
                html: "<small>" + error.response.data.error + "</small>",
                showConfirmButton: true,
                confirmButtonColor: "#00364f",
              });
        });
    },
    editServiceSteps(srvStep) {
      this.form_step_update_id = srvStep.id;
      this.form_step.service_id = srvStep.service_id;
      this.form_step.step_id = srvStep.step_id;
      this.form_step.priority = srvStep.priority;
      this.edit = "true";
    },
    getServiceSteps() {
      this.$axios
        .get(
          `superadmin/servicesteps?orderBy=priority&service_id=${this.form_step.service_id}`,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          this.serviceSteps = res.data.data.data;
        });
    },
    deleteServiceSteps(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .delete(`superadmin/servicesteps/${id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                console.log(res.data.data.data);

                toast.success("Service Steps Deleted  Succesfully", {
                  autoClose: 1000,
                });
                this.getServiceSteps();
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
          console.log(error.response);
        
          this.$swal.fire({
                position: "top-center",
                icon: "error",
                html: "<small>" + error.response.data.error + "</small>",
                showConfirmButton: true,
                confirmButtonColor: "#00364f",
              });
        });
          }
        });
    },
    servDocumentDelete(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .delete(`superadmin/servicedocument/${id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                console.log(res.data.data.data);

                toast.success("Service Document Deleted  Succesfully", {
                  autoClose: 1000,
                });

                this.LoadDocuments();
              })
              .finally(() => (this.loading = false))
              .catch((error) => {

this.$swal.fire({
      position: "top-center",
      icon: "error",
      title: "<small>" + error.response.data.error + "</small>",
      showConfirmButton: true,
      confirmButtonColor: "#00364f",
    });



  });
          }
        });
    },
    saveServiceSteps() {
      this.form_step.status = "true";
      this.loading = true;
      this.$axios
        .post("superadmin/servicesteps", this.form_step, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;

          toast.success("Service Step Created Succesfully ", {
            autoClose: 1000,
          });
          this.form_step.priority = "";
          this.form_step.step_id = null;
          this.form_step.errors = {};
          this.form_step.status = "";
          this.getServiceSteps();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
          this.form_step.errors = error.response.data;

          this.$swal.fire({
                position: "top-center",
                icon: "error",
                html: "<small>" + error.response.data.error + "</small>",
                showConfirmButton: true,
                confirmButtonColor: "#00364f",
              });
        });
    },
    getStepsForservices() {
      this.$axios
        .get("superadmin/steps", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.steps = res.data.data.data;
        });
    },
    stepslinking(service) {
this.clearStepForm()
      this.formStepCreate = {};
          this.formStepCreate.errors = {};


      this.form_step.errors = {};
      this.form_step.service_id = service.id;
   
      // this.loading = true;
      this.getStepsForservices();

      this.getServiceSteps();

      this.form_step.step_id = this.findMinVal(this.steps);
      // .finally(() => (this.loading = false));
    },
    findMinVal(data) {
      console.log(data);
      var arr = [];

      for (var i = 0; i < data.length; i++) {
        arr.push(data[i].id);
      }

      console.log(arr);
      var r = Math.min.apply(Math, arr);
      console.log(r);
      return r;
    },
    memberAddonsGet() {
      this.$axios
        .get(`superadmin/addonmemberservice?service_id=${this.service_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.memberAddons = res.data.data.data;
        });
    },
    membereAddonesValueOfMember(memberAddons, members, event) {
      var form = {
        service_id: this.service_id,
        member_addon_id: memberAddons.id,
        members: members,
        amt: event.target.value,
        status: "true",
      };

      this.$axios
        .post("superadmin/saveservicememberaddon", form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
        });
    },
    valueCheckerMemberFees(membercharges, members) {
      for (let i = 0; i < membercharges.length; i++) {
        if (membercharges[i].members == members) {
          return membercharges[i].amt;
        }
      }
    },
    // member wise end

    // member addon charges gst reqired save area

    memberAddonChargesGstRequiredSave(event, memberAddons) {
      this.gstRequiredValues_form.service_id = this.service_id;
      this.gstRequiredValues_form.member_addon_id = memberAddons.id;
      this.gstRequiredValues_form.gstRequired = event.target.value;
    },

    addonGstSave(memberAddons) {
      this.gstRequiredValues_form.service_id = this.service_id;
      this.gstRequiredValues_form.member_addon_id = memberAddons.id;
      this.loading = true;
      this.$axios
        .post("superadmin/saveservicememberaddongst", this.gstRequiredValues_form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Member Addon Save Succesfully ", {
            autoClose: 1000,
          });
          this.memberAddonsGet();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error);
        });

      // .finally(() => (this.loading = false));
    },

    valueChekerStateCharges(serviceCharges, authrizationCapital) {
      $("input").text("");

      for (let i = 0; i < serviceCharges.length; i++) {
        if (serviceCharges[i].authrizationCapital == authrizationCapital) {
          return serviceCharges[i].governmentFee;
        }
      }
    },
    stateServiceChargeSave(state, authrizationCapital, event) {
      var service_frm = {
        authrizationCapital: authrizationCapital,
        state_id: state.id,
        service_id: this.service_id,
        governmentFee: event.target.value,
      };

      this.$axios
        .post("superadmin/saveservicecharge", service_frm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;

        

          toast.success("State Charges Added Succesfully", {
              autoClose: 1000,
            }); 
        })    .catch((error) => {

this.$swal.fire({
      position: "top-center",
      icon: "error",
      title: "<small>" + error.response.data.error.governmentFee[0] + "</small>",
      showConfirmButton: true,
      confirmButtonColor: "#00364f",
    });



  });


      
    },

    stateServiceChargesInfo() {
      this.$axios
        .get(`superadmin/stateservicecharges?all=all&service_id=${this.service_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.allstates = res.data.data;
        });

      $(".form-control").val("");
    },
    otherFeesDelete(otherFee) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .delete(`superadmin/serviceotherexpense/${otherFee.id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;

                toast.success("Other Fees Deleted  Succesfully", {
                  autoClose: 1000,
                });
                this.gvtFees(this.serviceGvt);
              });
            this.otherFees.gstRequired = "false";
          }
        });
    },
    editGvtFeesOther(record) {
      this.otherFees = record;
      console.log(this.otherFees);
      this.editOtherFees = "true";
    },

    updateGvtFeesOther() {
      this.loading = true;
      this.$axios
        .put(`superadmin/serviceotherexpense/${this.otherFees.id}`, this.otherFees, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;

          toast.success("Other Fees Updated  Succesfully", {
            autoClose: 1000,
          });
          this.otherFees.title = "";
          this.otherFees.amt = null;
          this.otherFees.gstRequired = "false";
          this.editOtherFees = "false";
          this.gvtFees(this.serviceGvt);
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error);
        });
    },
    saveGvtFees() {
      this.loading = true;
      this.$axios
        .put(
          `superadmin/service/${this.Form_service_update.id}`,
          this.Form_service_update,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          this.services = res.data.data.data;

          toast.success("Service Updated  Succesfully", {
            autoClose: 1000,
          });
          this.getServices();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {

this.$swal.fire({
      position: "top-center",
      icon: "error",
      title: "<small>" + error.response.data.error.governmentFee[0] + "</small>",
      showConfirmButton: true,
      confirmButtonColor: "#00364f",
    });



  });


        
    },
    classActiveTab(){
      this.isActive = 'active'

    },
    gvtFees(serviceGvt) {
      this.memberWiseFeeEdit = "false";
      this.memberWiseFee_form = {};
      this.memberWiseFee_form.errors = {};

      this.memberWiseFee_form.service_id = serviceGvt.id;
      this.service_id = serviceGvt.id;
      this.gvtFeesTypeCompare = serviceGvt.gvtFeeType;
      this.serviceGvt = serviceGvt;
      this.otherFees.service_id = serviceGvt.id;
      this.memberWiseFee_form.status = "true";
      this.memberWiseFee_form.gstRequired = "false";
      this.gstRequiredValues_form.service_id = serviceGvt.id;
      this.loadMemberCharges();

      // this.loading = true;
      this.$axios
        .get(`superadmin/serviceotherexpense?all=all&service_id=${this.service_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.otherFeesInfo = res.data.data;
        });

      this.stateServiceChargesInfo();
      this.memberAddonsGet();
      this.Form_service_update = serviceGvt;
      // .finally(() => (this.loading = false));
    },
    checkOtherFeesGstRequired(event) {
      if (event.target.checked) {
        this.otherFees.gstRequired = String("true");
        this.otherFees.gstRequired.toString();
      } else {
        this.otherFees.gstRequired = "false";
      }
    },
    otherFeesSave() {
      this.loading = true;
      this.$axios
        .post("superadmin/serviceotherexpense", this.otherFees, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;

          toast.success("OtherFees Added Succesfully", {
            autoClose: 1000,
          });
          this.otherFees.title = "";
          this.otherFees.amt = null;
          this.otherFees.gstRequired = "false";
          this.gvtFees(this.serviceGvt);
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error);
        });
    },
    // nextFiveState(start, end) {
    //   console.log(end);
    //   this.end = start + this.allstates.length;
    // },
    // lastFiveState(start, end) {
    //   console.log(end);
    //   console.log(start);
    //   this.start = 0;
    //   this.end = 5;
    // },
    check(value, index, event) {
      if (value) {
        if (!event.target.checked) {
          // this.loading = true;
          this.$axios
            .delete("superadmin/servicemodulecharge/" + value, {
              headers: { Authorization: "Bearer " + localStorage.accessToken },
            })
            .then((res) => {
              res;
              this.servicemodules[index].service_module_charge_id = null;
              this.servicemodules[index].amt = "";
              this.servicemodules[index].gstRequired = "";

              toast.success("Service Moduls Delete  Succesfully", {
                autoClose: 1000,
              });
            });
          // .finally(() => (this.loading = false));
        }
      }
    },

    // for new service modules
    newSaveServiceModules() {
      this.$axios
        .post(
          "superadmin/bulkservicemodulecharge",
          { serviceCharges: this.servicemodules },
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          res;
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Service Moduls Linked Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });
          this.getServices();
        });
    },
    amountForModule(event, index, modules) {
      // console.log(index);
      index;
      // console.log(modules);
      modules;
      this.createServiceModuleForm.amt = event.target.value;
      // console.log(index);
      // this.servicemodules[index].amt = event.target.value;
      // this.servicemodules[index].service_module_id = modules.id;
      // this.servicemodules[index].service_id = this.service_id;
      // console.log(this.servicemodules[index]);
    },
    gstReqForModule(event, index) {
      index;
      // console.log(event.target.value + " " + index);
      // this.servicemodules[index].gstRequired = "true";
      this.createServiceModuleForm.gstRequired = event.target.value;
      // this.servicemodules[index].service_module_id = modules.id;
      // this.servicemodules[index].service_id = this.service_id;
      // console.log(this.servicemodules[index]);
    },
    createServieModuleCharge(module_id) {
      // this.servicemodules.forEach((value, index) => {

      //   this.servicemodules[index].gstRequired = value.gstRequired;

      // });

      this.loading = true;

      // { serviceCharges: this.servicemodules }
      this.createServiceModuleForm.service_id = this.service_id;
      this.createServiceModuleForm.service_module_id = module_id;
      this.$axios
        .post("superadmin/bulkservicemodulecharge", this.createServiceModuleForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;

          toast.success("Service Module Succesfull", {
            autoClose: 1000,
          });
          this.getServiceModulesNew();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // error = error.data.message
          console.log(error);
        });
    },

    deleteServiceModuleChagesNew(module_id) {
      console.log(module_id);
      // this.servicemodules.forEach((value, index) => {

      //   this.servicemodules[index].gstRequired = value.gstRequired;

      // });

      this.loading = true;

      // { serviceCharges: this.servicemodules }
      // this.createServiceModuleForm.service_id = this.service_id;
      // this.createServiceModuleForm.service_module_id = module_id;
      this.$axios
        .delete(`superadmin/servicemodulecharge/${module_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;

          toast.success("Service Module Deleted Succesfully", {
            autoClose: 1000,
          });
          this.getServiceModulesNew();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // error = error.data.message
          console.log(error.response);
          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error,
          });
        });
    },
    getModuleInfo() {
      this.$axios
        .get(`superadmin/servicemodulecharge?all=all&service_id=${this.form.serviceId}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.moduleInfo = res.data.data;
          var serModule = this.servicemodules;
          var ModuleId = [];
          // for loop start
          _.forEach(this.moduleInfo, function (value) {
            if (
              _.find(serModule, function (o) {
                return o.id == value.service_module_id;
              })
            ) {
              var index = _.findIndex(serModule, function (o) {
                return o.id == value.service_module_id;
              });
              serModule[index].amt = value.amt;

              serModule[index].gstRequired = value.gstRequired;
              serModule[index].service_module_charge_id = value.id;
              ModuleId.push(value.service_module_id);
            }
          });
          this.servicemodules = serModule;
          this.form.id = ModuleId;

          // end for loop
        });
    },
    addOns(service) {
      this.form.serviceId = service.id;
      this.service_id = service.id;
      this.getServiceModulesNew();
    },
    getServiceModulesNew() {
      this.$axios
        .get("superadmin/servicemodule?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.servicemodules = res.data.data;
          this.getModuleInfo();
        });
    },
    editServices(service) {

      this.clearStepForm()

      $("#maxmamValidation").html("<small></small>");
      $("#minmamValidation").html("<small></small>");

      console.log(service);
      this.Form_service_update_id = service.id;
      // this.Form_service_update.code = service.code;
      // this.Form_service_update.name = service.name;
      // this.Form_service_update.minMember = service.minMember;
      // this.Form_service_update.maxMember = service.maxMember;
      // this.Form_service_update.minCapital = service.minCapital;
      // this.Form_service_update.superAdminFee = service.superAdminFee;
      // this.Form_service_update.adminFee = service.adminFee;
      // this.Form_service_update.professionalFee = service.professionalFee;
      // this.Form_service_update.status = service.status;
      // this.Form_service_update.remark = service.remark;
      // this.Form_service_update.governmentFee = service.governmentFee;
      // this.Form_service_update.adminFee = service.adminFee;
      this.Form_service_update = service;
      this.service_id = service.id;

      if (service.logo != null) {
        this.serviceIconImg =
          this.$store.state.imgUrl +
          "/service-icons/" +
          service.id +
          "/thumbs/" +
          service.logo;
      } else {
        this.serviceIconImg = this.$store.state.placeholderImg;
      }
    },
    updateServices() {
      if (this.maxmamValidation == "false" && this.minmamValidation == "false") {
        this.loading = true;
        this.$axios
          .put(
            `superadmin/service/${this.Form_service_update_id}`,
            this.Form_service_update,
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            this.services = res.data.data.data;
            toast.success("Services Updated Succesfully ", {
              autoClose: 1000,
            });

            $("#editexampleModal").modal("hide");
            this.getServices();
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.Form_service_update.errors=     error.response.data

// this.$swal.fire({
//       position: "top-center",
//       icon: "error",
//       title: "<small>" + error.response.data.error.governmentFee[0] + "</small>",
//       showConfirmButton: true,
//       confirmButtonColor: "#00364f",
//     });



  });
      }
    },
    getServices(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
     

        if( this.filterType == 'all'){
            this.filterValue = ''
            this.filterType = ''
          }


          if(this.filterValue != null && this.filterType != 'all'){
         
            if(this.filterType != 'all'){

              pageUrl += `superadmin/service?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
        }else{
            pageUrl += `superadmin/service?per_page=${this.per_page}`;
           }

        
          }
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.services = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },

    loadMytestServices() {
      this.$axios
        .get("superadmin/service?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.allServices = res.data.data;
        });
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.service
    console.log(this.$store.state.superAdminPageTitles.service)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.getServices();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.steps-link {
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.steps-link:hover {
  border: 1px solid #888;
  background-color: #ddd;
}

.modal-bg {
  background-image: url(/assets/image/bg.jpg);
  background-size: cover;
  height: 100%;
  /* background-color: #f21300; */
}

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}


.gtsApp {
  padding: 5px 8px 5px 8px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 100%;
  box-shadow: 2px 2px 5px gray;
  font-size: 8pt;
}

.form-check-input:focus {
  border-color: #00364f;
  outline: 0;
  box-shadow: 0px 2px 4px 0 #00364f3a;
}

.h4-heading {
  color: red;
;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

.form-check-input[data-v-742849ee]:checked {
  background-color: #f8f8f8;
  border-color: #e74646;
  border-width: 5px;
}
.dropdown-item:hover {
  background: #ff00001a;
  color: #f21000;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}





.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
